import api from './api';

import { UserLoginForm } from '../views/Login/Login';

export interface IUser {
  name: string
  email: string
}

export interface IContext {
  id: number,
  title: string,
  status: string,
  updated_at: string,
  user: IUser
}

export interface IReport {
  reference_name: string,
  reference_code: string,
  media_calls_by_students: number,
  total_students: string,
  total_calls: string,
  total_calls_sum: string;
}

export interface ICalled {
  id: number,
  name: string,
  total_calls: number,
}

export interface ICalleds{
  data: ICalled[],
  total: number,
  current_page: number,
  last_page: number,
  id: number
}

export interface IFilter {
  page: number;
  reference_code: string
  reference_name: string,
  type: string;
  updated_at: string | null;
  created_at: string | null;
  keyword: string;
}

export interface ICalledFilter{
  name: string,
  page: number
}

export interface ITutorFilter{
  name: string,
  email: string,
  page: number
}

export interface ITutor{
  id: number,
  name: string,
  email: string,
  created_at: string,
  password: string,

}

export interface ITutors{
  data: ITutor[],
  total: number,
  current_page: number,
  last_page: number,
  id: number
}

export const login = (data: UserLoginForm) => Promise.resolve(api.post('/api/v1/tutoring/admin/login', data));

export const rememberPassword = (data: { email: string, token: string|null }) => Promise.resolve(api.post(`/api/v1/tutoring/remember-password`, data));

export const getContexts = (data: { status: string[] }) => Promise.resolve(api.get(`/api/v1/tutoring/admin/context`, {params: data}));

export const getCallReport = (data:IFilter) => Promise.resolve(api.get(`/api/v1/tutoring/admin/context/call-report`,{params:data}));

export const getContext = (id: string) => Promise.resolve(api.get(`/api/v1/tutoring/admin/context/` + id));

export const putContext = (id: string, data: {status: string}) => Promise.resolve(api.put(`/api/v1/tutoring/admin/context/` + id, data));

export const storeOrUpdateMessage = (data : { description: string, context_id: number, message_id?: number }) => Promise.resolve(api.post(`/api/v1/tutoring/admin/message/`, data));

export const getDashboard = () => Promise.resolve(api.get(`/api/v1/tutoring/admin/`));

export const storeOrUpdateTutor = (data : ITutor) => Promise.resolve(api.post(`/api/v1/tutoring/admin/tutor/`, data));

export const getTypeCalleds = (data: { page: number }) => Promise.resolve(api.get(`/api/v1/tutoring/admin/context-type`, {params: data}));

export const storeOrUpdateContextType = (data : ICalled) => Promise.resolve(api.post(`/api/v1/tutoring/admin/context-type/`, data));

export const getTotalContextType = (data : ICalled) => Promise.resolve(api.post(`/api/v1/tutoring/admin/context-type/total`, data));

export const getContextType = (id: number) => Promise.resolve(api.get(`/api/v1/tutoring/admin/context-type/` + id));

export const deleteContextType = (id: number) => Promise.resolve(api.delete(`/api/v1/tutoring/admin/context-type/` + id));

export const getTutors = (data: { page: number }) => Promise.resolve(api.get(`/api/v1/tutoring/admin/tutor`, {params: data}));

export const getTutor = (id: number) => Promise.resolve(api.get(`/api/v1/tutoring/admin/tutor/` + id));

export const deleteTutor = (id: number) => Promise.resolve(api.delete(`/api/v1/tutoring/admin/tutor/` + id));

export const updateTypeContext = (id: string, data: { type: string }) => Promise.resolve(api.post(`/api/v1/tutoring/admin/type-context/` + id, data));

