import React, {useEffect, useState} from 'react';
import {useQuery} from "react-query";
import {Row, Col, Form, Label} from "reactstrap";

import Select from 'react-select';

import DatePicker, {registerLocale} from "react-datepicker";

import styles from "./styles.module.scss";

import {getCallReport, IReport} from "../../services/services";

import {checkLogout401} from "../../services/auth";


import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';

import Preloader from "../../component/Preloader";
import Pagination from "../../component/Pagination/Pagination";


export interface IFilter {
  page: number;
  perPage: number;
  reference_code: string
  reference_name: string,
  type: string;
  updated_at: string | null;
  created_at: string | null;
  keyword: string;
}

interface IReferences {
  reference_code: string;
  reference_name?: string;
};

const CallReport = () => {
  registerLocale('ptBR', ptBR);
  const [filter, setFilter] = useState<IFilter>({
    page: 1,
    perPage: 30,
    reference_code: '',
    reference_name: '',
    type: '',
    updated_at: '',
    created_at: '',
    keyword: ''
  });


  const [filterAux, setFilterAux] = useState<IFilter>({
    page: 1,
    perPage: 30,
    reference_code: '',
    reference_name: '',
    type: 'modificacao',
    updated_at: '',
    created_at: '',
    keyword: ''
  });

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([new Date(), new Date()]);
  const [dateRangeCreate, setDateRangeCreate] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [currentPage, setCurrentPage] = useState(1);

  const {
    isLoading,
    data,
  } = useQuery(['reports', filter], () => getCallReport(filter), {
    onSuccess: ({data}) => {
    },
    onError: error => {
      checkLogout401(error)
    },
    refetchOnWindowFocus: true,
    staleTime: 2000,
    enabled: true,
  });

  useEffect(() => {
    console.log(data)
  }, [data]);

  const references = data
    ? data?.data?.results?.map((item: IReferences) => ({
      value: item.reference_code,
      label: item.reference_name,
    }))
    : [];

  const handleChangeSelect = (reference_code: string, selectedOption: any) => {
    if (!selectedOption) {
      setFilterAux({
        ...filterAux,
        [reference_code]: null,
      });
    } else {
      setFilterAux({
        ...filterAux,
        [reference_code]: selectedOption.value ? selectedOption.value : null,
      });
    }
  };

  const handleChangeValue = (name: string, value: any) => {
    setFilterAux({
      ...filterAux,
      [name]: value,
    });
  };

  useEffect(() => {
    filterAux.page = filter.page;
  }, [filter])

  useEffect(() => {
    const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    setDateRange([firstDayOfMonth, new Date()]);
  }, []);

  const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

  return (
    <>
      <Preloader isVisible={isLoading}/>
      <div className={styles.content}>
        <div className={styles.box}>
          <div className={styles.boxHeader}>
            <div className={styles.filter}>
              <Form onSubmit={(e) => {
                e.preventDefault();
                setFilter(filterAux);
              }}>
                <Row className={"row-cols-lg-auto g-3 align-items-center"}>
                  <Col xl={3}>
                    <Select
                      isClearable
                      options={references}
                      placeholder={'Nome do Curso'}
                      onChange={(selectedOption) => handleChangeSelect("reference_code", selectedOption)}
                    />
                  </Col>
                  <Col xl={3}>
                    <Label
                      className="visually-hidden"
                      for={filterAux.type === "modificacao" ? "updated_at" : "created_at"}
                    >
                      Data
                    </Label>
                    <DatePicker
                      dateFormat={"dd/MM/yyyy"}
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      className={'form-control'}
                      placeholderText={'Data'}
                      locale={"ptBR"}
                      onChange={(update) => {
                        // @ts-ignore
                        setDateRange(update);

                        if (update[0] === null && update[1] === null)
                          handleChangeValue("date", null);
                        else
                          handleChangeValue("date", update.map((item) => {
                            return item ? item.toLocaleDateString() : null
                          }))
                      }}
                      isClearable={true}
                    />
                  </Col>
                  <Col xl={6} className={styles.colBtn}>
                    <button className={styles.filterBtn} disabled={false}>
                      {isLoading ? "Filtrando..." : "Filtrar"}
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <Col xl={12}>
            <div className={styles.totalItems}>
              <p className={styles.textDecoration}>Total: {data && data.data?.sum > 0 ? data.data.sum : ''}</p>
            </div>
          </Col>
          <div className={styles.table}>
            <table className='table table-responsive'>
              <thead>
              <tr>
                <th>Nome do Curso</th>
                <th>Total Chamados</th>
                <th>Total Alunos</th>
                <th>Média Chamados/Aluno</th>
              </tr>
              </thead>
              {data?.data?.results?.map((item: IReport) => {
                return (
                  <tbody key={item.reference_code}>
                  <tr>
                    <td>{item.reference_name}</td>
                    <td>{item.total_calls}</td>
                    <td>{item.total_students}</td>
                    <td>{Math.round(item.media_calls_by_students)}</td>
                  </tr>
                  </tbody>
                )
              })}
            </table>
          </div>
          <div className={styles.listing}>
            <Pagination
              itemsPerPage={filter.perPage}
              totalRegisters={data?.data?.total}
              paginate={paginate}
              filter={filter}
              setFilter={setFilter}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CallReport
