import React from "react";

import styles from "./styles.module.scss";

import seta from "../../assets/icons/arrow-back.svg";

import {Link} from "react-router-dom";

interface ButtonProps {
  to: string;
}

export default function Back({to}: ButtonProps) {

  return (
    <Link to={to} className={styles.arrowBack}>
      <img src={seta} alt={"voltar"}/> Voltar
    </Link>
  )
}
