import { useMemo } from 'react';
import getCookie from '../helper/getCookie';

export default function useAuth() {
  const existsJWT = getCookie('jwt_token_tutoria');

  const isAuthenticated = useMemo(() => {
    if (!existsJWT) {
      return false;
    }

    return true;
  }, [existsJWT]);

  return isAuthenticated;
}
