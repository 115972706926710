import React, {FC, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom'
import styles from "./styles.module.scss";
import Header from "../../component/Header/header";

import eye from "../../../src/assets/icons/eye.svg";
import ModalRemeberPassword from "../../component/ModalRemeberPassword/ModalRemeberPassword";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import {useMutation} from "react-query";
import {login} from "../../services/services";
import {toast} from "react-toastify";
import handleErrorMessage from "../../helper/handleErrorMessage";
import {useCookies} from "react-cookie";

import getRootDomain from "../../helper/domainHelper";
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import useAuth from "../../hooks/useAuth";
import Preloader from "../../component/Preloader";

export interface UserLoginForm {
  email: string,
  password?: string,
  social_origin?: string,
  access_token?: string,
  sub?: string,
  token?: string|null
}

const Login: FC = () => {

  document.title = 'Login';

  const {search} = useLocation();
  const isAuthenticated = useAuth();
  const next = new URLSearchParams(search).get('next');

  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [cookies, setCookie] = useCookies(["jwt_token_tutoria", "frontendname"]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email inválido').required('Preencha com seu email para continuar'),
    password: Yup.string().required('Senha inválida').min(1, 'Senha inválida').max(25, 'Senha inválida'),
  });

  const initialValues = {
    email: "",
    password: ""
  };

  const {mutate, isLoading} = useMutation((data: UserLoginForm) =>
    login(data), {
    onSuccess: (response) => {
      let data = response.data;
      if (data && data.token) {
        localStorage.setItem('jwt_token_tutoria', data.token);
        setCookie("jwt_token_tutoria", data.token, {
          domain: window.location.hostname,
          path: "/",
          maxAge: 3600 * 24 * (process.env.REACT_APP_APP_MAX_AGE_JWT_DAYS ? parseInt(process.env.REACT_APP_APP_MAX_AGE_JWT_DAYS) : 30)
        });
        toast.success("Login com sucesso.");

        if (next) {
          window.location.href = next.includes("http://") || next.includes("https://") ? next : '/' + next;
        }

        navigate('/dashboard');
      } else {
        toast.error(data.message);
      }
    },
    onError: (error: { message: string, response: { status: number, data: { errors: [] } } }) => {
      toast.error(error.message);
    }
  });

  const renderError = (message: any) => <p className={styles.error}>{message}</p>;

  const onSubmit = async (data: UserLoginForm) => {
    let token = null;
    if(executeRecaptcha) {
      token = await executeRecaptcha('login');
    }
    mutate({...data, token: token})
  };

  useEffect(() => {

    if(isAuthenticated && next) {
      window.location.href = next.includes("http://") || next.includes("https://") ? next : '/' + next;
    }

  }, [isAuthenticated, next]);

  return (
    <>
      <Preloader isVisible={isLoading}/>
      <Header/>
      <div className={styles.login}>
        <div className={styles.content}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}
          >
            <Form>
              <div className={styles.boxLogin}>
                <div className={styles.resize}>
                  <h1>Acessar e continuar</h1>
                </div>
                <div className={styles.inputGroup}>
                  <p>Email</p>
                  <Field
                    name="email"
                    placeholder="Informe seu e-mail"
                    type="text"
                  />
                  <ErrorMessage className={styles.error} name="email" render={renderError}/>
                </div>
                <div className={styles.inputGroup}>
                  <p>Senha</p>
                  <Field name="password"
                         type={showPassword ? "text" : "password"}
                         placeholder="Informe sua senha"
                  />
                  <img src={eye} alt="show pass" onClick={() => setShowPassword(!showPassword)}/>
                  <ErrorMessage className={styles.error} name="password" render={renderError}/>
                </div>
                <div className={styles.formGroup}>
                  <button disabled={isLoading} type="submit">Entrar</button>
                </div>
                {/*<div className={styles.group}>
                  <button className={styles.remember} onClick={() => setShowModal(!showModal)}>Esqueci
                    minha senha
                  </button>
                </div>*/}
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      {showModal &&
        <ModalRemeberPassword
          closeModal={() => setShowModal(!showModal)}
          modalOpen={showModal}
        />
      }
    </>
  )
}

const TheLogin = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ? process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY : ""}>
      <Login/>
    </GoogleReCaptchaProvider>
  )
}

export default TheLogin

