import styles from "./styles.module.scss";
import {Modal, ModalBody} from "reactstrap";
import React, {useEffect, useState} from "react";
import {CSpinner} from "@coreui/react";
import {Button, Input} from "reactstrap";
import {
  getContextType,
  ICalled,
} from "../../services/services";
import {toast} from "react-toastify";
import {checkLogout401} from "../../services/auth";

interface ModalProps {
  saving: boolean;
  open: boolean;
  idTypeCalled: number;
  setOpen: (data: boolean) => void;
  action: (data: ICalled) => void;
}

const ModalStoreOrUpdateTypeCalled = ({open, idTypeCalled, setOpen, action, saving}: ModalProps) => {

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [contextType, setContextType] = useState<ICalled>({
    id: idTypeCalled,
    name: '',
    total_calls: 0,
  })

  useEffect(() => {
    if (idTypeCalled > 0) {
      setLoading(true)
      getContextType(idTypeCalled).then((result) =>{
        if (result && result.data){
          setContextType({
            ...contextType,
            id: result.data.id,
            name: result.data.name
          })
        }
      }).catch((e)=>{
        checkLogout401(e)
      }).finally(()=>{
        setLoading(false)
      })
    }
  }, [idTypeCalled])

  const handleChange = (name: string, value: string) => {
    setContextType({
      ...contextType,
      [name]: value
    })
  }

  const toggle = () =>{
    setOpen(false)
  }

  const verify = () =>{
    if (((contextType.name === '') && contextType.id === 0) || ((contextType.name === '') && contextType.id > 0)){
      toast.error("Preencha todos os campos!");
      return false
    }

    action(contextType)
  }
  return (
    <Modal toggle={toggle} isOpen={open} className={styles.modalContent}>
      <div className={styles.close}>
        <button onClick={toggle}>&times;</button>
      </div>
      <strong>{idTypeCalled > 0 ? 'Editar tipo de classificação' : 'Adicionar tipo de classificação'}</strong>
      {loading ?
        <div className={styles.spinner}>
          <CSpinner className={styles.loading}/>
        </div>
        :
        <ModalBody>
          <div className={styles.inputCards}>
            <div>
              <small>Tipo de classifição:</small>
              <Input
                id="name"
                name="name"
                type="text"
                value={contextType.name}
                onChange={(e) => {
                  handleChange('name', e.target.value)
                }}
              />
            </div>
          </div>
          <div className={styles.inputButtons}>
            <Button disabled={saving} className={saving ? styles.buttonDisable : ""} onClick={toggle}>
              {saving ? 'Carregando...' : 'Cancelar'}
            </Button>
            <Button disabled={saving} className={saving ? styles.buttonDisable : ""} onClick={verify}>
              {saving ? 'Carregando...' : 'Salvar'}
            </Button>
          </div>
        </ModalBody>
      }
    </Modal>
  )

}
export default ModalStoreOrUpdateTypeCalled
