import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

import styles from "./styles.module.scss";

import {useQuery} from "react-query";
import {getDashboard} from "../../services/services";
import {checkLogout401} from "../../services/auth";
import Preloader from '../../component/Preloader';

import resolved from "../../assets/icons/respondida.svg";
import pending from "../../assets/icons/pending.svg";
import answered from "../../assets/icons/answered.svg";


const Dashboard = () => {

  document.title = 'Tutoria Nova Concursos';

  const navigate = useNavigate();

  const {
    isLoading,
    data,
  } = useQuery(['dashboard'], () => getDashboard(), {
    onSuccess: ({data}) => {
    },
    onError: error => {
      checkLogout401(error);
    },
    refetchOnWindowFocus: true,
    staleTime: 2000,
    enabled: true,
  })


  return (
    <><Preloader isVisible={isLoading}/>
      <div className={styles.content}>
        <div className={styles.box}>
          <div className={styles.card}>
            <p>Perguntas Pendentes</p>
            <div className={styles.cardBox} onClick={() => navigate('/context?status=pendente')}>
              <img loading={"lazy"} className={styles.pending} src={pending} alt={'pendente'}/>
              {data &&
                <p
                  className={styles.number}>{data.data.Totals.pending}<span> {data.data.Totals.pending === 1 ? 'Pergunta pendente.' : 'Perguntas pendentes'}</span>
                </p>
              }
            </div>
          </div>
          <div className={styles.card}>
            <p>Perguntas Respondidas</p>
            <div className={styles.cardBox} onClick={() => navigate('/context?status=respondido')}>
              <img loading={"lazy"} className={styles.answered} src={answered} alt={answered}/>
              {data &&
                <p
                  className={styles.number}>{data.data.Totals.answered}<span> {data.data.Totals.answered === 1 ? 'Pergunta respondida.' : 'Perguntas respondidas'}</span>
                </p>
              }
            </div>
          </div>
          <div className={styles.card}>
            <p>Perguntas Resolvidas</p>
            <div className={styles.cardBox} onClick={() => navigate('/context?status=resolvido')}>
              <img loading={"lazy"} className={styles.resolved} src={resolved} alt={resolved}/>
              {data &&
                <p
                  className={styles.number}>{data.data.Totals.resolved}<span> {data.data.Totals.resolved === 1 ? 'Pergunta resolvida.' : 'Perguntas resolvidas'}</span>
                </p>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
