import React, {useEffect, useState} from 'react';
import styles from "./styles.module.scss";
import {useMutation, useQuery} from "react-query";
import {
  deleteTutor,
  getTutors,
  ITutor,
  ITutorFilter,
  ITutors,
  storeOrUpdateTutor
} from "../../services/services";
import {checkLogout401} from "../../services/auth";
import {Button, Input} from "reactstrap";
import ModalStoreOrUpdateTutor from "../../component/ModalStoreOrUpdateTutor";
import {queryClient} from "../../services/queryClient";
import {toast} from "react-toastify";
import Modal from "../../component/Modal";
import editIcon from '../../assets/icons/edit.svg';
import deleteIcon from '../../assets/icons/graytrash.svg';
import ModalConfirm from "../../component/ModalConfirm";
import Preloader from "../../component/Preloader";

const Tutors = () => {

  const [tutors, setTutors] = useState<ITutors>({
    total: 0,
    last_page: 1,
    current_page: 1,
    data: [],
    id: 0
  })
  const [filter, setFilter] = useState<ITutorFilter>({
    page: 1,
    email: '',
    name: ''
  });

  const [aux, setAux] = useState<ITutorFilter>({
    page: 1,
    email: '',
    name: ''
  });

  const [openModalCreate, setOpenModalCreate] = useState(false)
  const [openModalUpdate, setOpenModalUpdate] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [saving, setSaving] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [idTutor, setIdTutor] = useState(0)

  const {
    data,
    isLoading
  } = useQuery(['tutors', filter.name, filter.email, filter.page], () => getTutors(filter), {
    onSuccess: () => {
    },
    onError: error => {
      checkLogout401(error)
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: true,
  })

  const handleChange = (name: string, value: string) => {
    setAux({
      ...aux,
      [name]: value
    })
  }
  const filterAction = () => {
    setFilter({
      ...filter,
      name: aux.name,
      email: aux.email
    })
  }

  const {mutate} = useMutation((data: ITutor) => storeOrUpdateTutor(data), {
    onSuccess: ({data}) => {
      if (data.success) {
        queryClient.invalidateQueries(['tutors', filter.name, filter.email, filter.page]);
        toast.success(data.msg);
        if (openModalCreate) {
          setOpenModalCreate(false)
        } else {
          setOpenModalUpdate(false)
        }
      } else {
        toast.error(data.msg);
      }
      setSaving(false)
    },
    onError: (error => {
      console.error(error);
      toast.error("Erro inesperado tente mais tarde");
    })
  })

  const storeOrUpdate = (tutor: ITutor) => {
    setSaving(true)
    mutate(tutor)
  }

  const destroyTutor = () => {
    setLoadingDelete(true)
    deleteTutor(idTutor).then((result) => {
      if (result.data && result.data.success) {
        queryClient.invalidateQueries(['tutors', filter.name, filter.email, filter.page]);
        toast.success(result.data.msg)
      } else {
        toast.error(result.data.msg)
      }
    }).catch((e) => {
      checkLogout401(e)
    }).finally(() => {
      setOpenModalConfirm(false)
      setLoadingDelete(false)
    })
  }


  useEffect(() => {
    if (data) {
      setTutors({
        ...tutors,
        data: data.data.tutors ?? [],
        total: data.data.total ?? 0,
        last_page: data.data.last_page ?? 1,
        current_page: data.data.current_page ?? 1

      })
    }
  }, [data])
  return (

    <div className={styles.content}>
      <div className={styles.box}>
        <div className={styles.filter}>
          <div className={styles.inputText}>
            <Input
              id="name"
              name="name"
              placeholder="Nome"
              type="text"
              onChange={(e) => {
                handleChange('name', e.target.value)
              }}
            />
            <Input
              id="email"
              name="email"
              placeholder="Email"
              type="email"
              onChange={(e) => {
                handleChange('email', e.target.value)
              }}
            />
          </div>
          <div className={styles.buttonFilter}>
            <Button disabled={isLoading} onClick={() => {
              filterAction()
            }}>
              {isLoading ? 'Carregando...' : 'Filtrar'}
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.add}>
        <Button disabled={isLoading} className={isLoading ? styles.buttonDisable : ''} onClick={() => {
          setOpenModalCreate(true)
          setIdTutor(0)
        }}>
          {isLoading ? 'Carregando...' : 'Adicionar'}
        </Button>
      </div>
      <div className={styles.table}>
        <p>Total: {isLoading ? '-' : data ? data.data.total : ''}</p>
        <table className='table table-responsive'>
          <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Data de Cadastro</th>
            <th>Editar</th>
            <th>Excluir</th>
          </tr>
          </thead>
          <tbody>
          <Preloader isVisible={isLoading}/>
          {tutors.data && tutors.data.map((tutor, index) => {
            return (
              <tr key={index}>
                <td>{tutor.name}</td>
                <td>{tutor.email}</td>
                <td>{tutor.created_at}</td>
                <td>
                  <Button className={styles.editButton} onClick={() => {
                    setIdTutor(tutor.id)
                    setOpenModalUpdate(true)
                  }}>
                    <img src={editIcon} alt={'edit'}/>
                  </Button>
                </td>
                <td>
                  <Button className={styles.deleteButton} onClick={() => {
                    setIdTutor(tutor.id)
                    setOpenModalConfirm(true)
                  }}>
                    <img src={deleteIcon} alt={'delete'}/>
                  </Button>
                </td>
              </tr>
            )
          })
          }
          </tbody>
        </table>
      </div>

      {openModalCreate &&
        <ModalStoreOrUpdateTutor action={storeOrUpdate} setOpen={setOpenModalCreate} open={openModalCreate} idTutor={0}
                                 saving={saving}/>
      }
      {openModalUpdate &&
        <ModalStoreOrUpdateTutor action={storeOrUpdate} setOpen={setOpenModalUpdate} open={openModalUpdate}
                                 idTutor={idTutor} saving={saving}/>
      }
      {openModalConfirm &&
        <ModalConfirm loading={loadingDelete}
                      open={openModalConfirm}
                      title={'Excluir'}
                      description={'Deseja mesmo excluir esse tutor?'}
                      textButton={'Excluir'}
                      setOpen={(state) => {
                        setOpenModalConfirm(state)
                      }}
                      action={destroyTutor}
                      btnConfirmColor={"danger"}
        />
      }
    </div>

  )
}

export default Tutors
