import React, {useEffect, useState} from 'react';

import styles from "./styles.module.scss";

import {useParams, useNavigate} from 'react-router-dom';
import {useMutation, useQuery} from "react-query";

import {getContext, getTypeCalleds, ICalled, login, putContext, updateTypeContext} from "../../services/services";
import {checkLogout401} from "../../services/auth";
import {IMessage} from "../../ts/interfaces/IMessage";

import MessageQuill from "../MessageQuill/MessageQuill";
import Preloader from '../../component/Preloader';

import Back from "../../component/Back/Back";

import {FiCheckCircle, FiExternalLink} from 'react-icons/fi';
import {toast} from "react-toastify";
import {EnumContextStatus} from "../../services/enum";
import {queryClient} from "../../services/queryClient";
import ModalConfirm from "../../component/ModalConfirm";
import Select from "react-select";
import {selectOptions} from "@testing-library/user-event/dist/select-options";

const ContextDetail = () => {

  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [references, setReferences] = useState<ICalled[]>([])
  const params = useParams();
  const navigate = useNavigate();

  const {
    data,
    isLoading
  } = useQuery(['contexts', params.id], () => getContext(params.id ? params.id : '0'), {
    onSuccess: ({data}) => {
      if (!data) {
        navigate('/context');
      }
    },
    onError: error => {
      checkLogout401(error)
    },
    refetchOnWindowFocus: true,
    staleTime: 2000,
    enabled: true,
  })

  const {
    data: dataTypes,
    isLoading : isLoadingTypes
  } = useQuery(['calleds'], () => getTypeCalleds({page:1}), {
    onSuccess: (data) => {

    },
    onError: error => {
      checkLogout401(error)
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: true,
  })

  useEffect(() => {
    if(dataTypes) {
      setReferences(dataTypes.data.context_types)
    }
  }, [dataTypes]);

  const {mutate, isLoading: isLoadingMutate} = useMutation((data: { status: string }) =>
    putContext(params.id ? params.id : '0', data), {
    onSuccess: (response) => {
      queryClient.invalidateQueries(['contexts']);
      setOpenModalConfirm(!openModalConfirm);
    },
    onError: (error: { message: string, response: { status: number, data: { errors: [] } } }) => {
      toast.error(error.message);
    }
  });

  const mutateType = useMutation((data: { type: string }) => {
    return updateTypeContext(params.id ? params.id : '0', data)
  }, {
    onSettled: (response) => {
      if (response) {
        if (response?.data?.success) {
          toast.success(response?.data?.msg ?? 'Tipo alterado com sucesso.')
        } else {
          toast.error(response?.data?.msg ?? 'Erro ao alterar tipo.')
        }
      } else {
        toast.error('Erro ao alterar tipo.')
      }
    },
    onError: (error: { message: string, response: { status: number, data: { errors: [] } } }) => {
      toast.error(error.message);
    }
  });

  return (<>
    <Preloader isVisible={isLoading}/>
    <Back to={"/context"}/>
    <div className={styles.contentWrapper}>

      <div className={styles.headerDetail}>
        {data && data.data.reference_name ? <>
          <p>{data.data.reference_name}</p>
          <div className={styles.headerBtns}>
            <Select
              isClearable
              defaultValue={{value:references.find((item) => item.name === data?.data.type)?.name, label: references.find((item) => item.name === data?.data.type)?.name} as any}
              options={references.map((item) => ({ value: item.name, label: item.name }))}
              isDisabled={isLoadingMutate || isLoading || mutateType.isLoading || isLoadingTypes}
              placeholder={'Tipo'}
              onChange={(selectedOption) => { mutateType.mutate({type: selectedOption?.value})}}
              className={styles.alignSelect}
            />
            {data.data.status !== EnumContextStatus.resolvido ?
              <button disabled={isLoadingMutate} onClick={() => {
                setOpenModalConfirm(!openModalConfirm)
              }}>Marcar como resolvido <FiCheckCircle/></button> : ""}
            <a target={"_blank"} rel={"nofollow"}
               href={process.env.REACT_APP_CORPORATIVO + "/sac/pedido/login/email?email=" + encodeURIComponent(data.data.user_email)}>Login
              Aluno <FiExternalLink/></a>
          </div>
        </> : ""}
      </div>

      {data && data.data.messages.map((item: IMessage) => {
        return <MessageQuill key={item.id} messageParameter={item} readonly={!item.is_tutor} contextId={data.data.id}/>
      })}

      {data && !data.data.messages[data.data.messages.length - 1].is_tutor ?
        <MessageQuill readonly={false} contextId={data.data.id}/> : ""}

    </div>

    <ModalConfirm loading={isLoadingMutate}
                  open={openModalConfirm}
                  title={'Marcar resolvido'}
                  description={'Deseja mesmo marcar como resolvido?'}
                  textButton={'Salvar'}
                  setOpen={(state) => {
                    setOpenModalConfirm(state)
                  }}
                  action={() => mutate({status: EnumContextStatus.resolvido})}
                  btnConfirmColor={"success"}/>
  </>)
}

export default ContextDetail;
