import styles from "./styles.module.scss";
import {Modal, ModalBody} from "reactstrap";
import React, {useEffect, useState} from "react";
import {CSpinner} from "@coreui/react";
import {Button, Input} from "reactstrap";
import {getContext, getTutor, ITutor, ITutorFilter, ITutors, storeOrUpdateTutor} from "../../services/services";
import {toast} from "react-toastify";
import {useMutation, useQuery} from "react-query";
import {queryClient} from "../../services/queryClient";
import {checkLogout401} from "../../services/auth";

interface ModalProps {
  loading: boolean;
  open: boolean;
  title: string;
  description: string;
  textButton: string;
  setOpen: (data: boolean) => void;
  action: () => void;
  btnConfirmColor: string
}

const ModalConfirm = ({open, title, description, textButton, setOpen, action, loading, btnConfirmColor}: ModalProps) => {
  const toggle = () =>{
    setOpen(false)
  }

  const getColorStyle = () => {
    if(btnConfirmColor === "danger") {
      return styles.danger;
    } else if(btnConfirmColor === "success") {
      return styles.success;
    }
    return styles.default;
  }

  return (
    <Modal toggle={toggle} isOpen={open} className={styles.modalContent}>
      <div className={styles.close}>
        <button onClick={toggle}>&times;</button>
      </div>
      <strong>{title}</strong>
      <ModalBody>
        <div className={styles.description}>
          <p>{description}</p>
        </div>
        <div className={styles.inputButtons}>
          <Button disabled={loading} className={loading ? styles.buttonDisable : ""} onClick={toggle}>
            {loading ? 'Carregando...' : 'Cancelar'}
          </Button>
          <Button disabled={loading} className={(loading ? styles.buttonDisable : "") + " " + getColorStyle()} onClick={action}>
            {loading ? 'Carregando...' : textButton}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )

}
export default ModalConfirm
