import {Route, Routes as Router, useLocation} from 'react-router-dom';
import {CookiesProvider} from 'react-cookie';

import Login from "../views/Login/Login";
import Home from "../views/Home/Home";


import Main from '../containers/Main';
import RequireAuth from './RequireAuth';

import Context from "../views/Context/Context";
import ContextDetail from "../views/ContextDetail/ContextDetail";
import Tutors from "../views/Tutors/Tutors";
import Dashboard from "../views/DashBoard/Dashboard";
import CallReport from "../views/CallReport/CallReport";
import Called from "../views/Called/Called";


export default function AppRouter() {
  return (
    <CookiesProvider>
      <Router>
        <Route path={'/login'} element={<Login/>}/>
        <Route
          element={(
            <RequireAuth>
              <Main/>
            </RequireAuth>
          )}>
          <Route path={'/'} element={<Dashboard/>}/>
          <Route path={'/dashboard'} element={<Dashboard/>}/>
          <Route path={'/tutors'} element={<Tutors/>}/>
          <Route path={'/context'} element={<Context/>}/>
          <Route path={'/context/:id'} element={<ContextDetail/>}/>
          <Route path={'/report'} element={<CallReport/>}/>
          <Route path={'/called'} element={<Called/>}/>
        </Route>
      </Router>
    </CookiesProvider>
  );
}
