import React, {FC, useState, useEffect} from 'react';

import styles from "./styles.module.scss";

import {IMessage} from "../../ts/interfaces/IMessage";

import ReactQuill from "react-quill";
import {toast} from "react-toastify";
import {queryClient} from "../../services/queryClient";
import {useMutation} from "react-query";

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';


import {storeOrUpdateMessage} from "../../services/services";

const MessageQuill: FC<{ contextId: number, messageParameter?: IMessage, readonly: boolean }> = ({messageParameter, contextId, readonly}) => {

  const [msg, setMsg] = useState("");
  const [message, setMessage] = useState(messageParameter);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{'header': 1}, {'header': 2}],               // custom button values
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
      [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
      [{'direction': 'rtl'}],                // text direction

      [{'header': [1, 2, 3, 4, 5, 6, false]}],

      [{'color': []}, {'background': []}],          // dropdown with defaults from theme
      [{'align': []}],

      ['link', 'image', 'clean']
    ]
  };

  const {
    isLoading,
    mutate,
  } = useMutation((data: { description: string, context_id: number, message_id?: number }) => storeOrUpdateMessage(data), {
    onSuccess: ({data}) => {
      setMessage(data);
      queryClient.invalidateQueries(['contexts']);
      queryClient.invalidateQueries(['dashboard']);
      toast.success("Salvo com sucesso.");
    },
    onError: (error => {
      console.error(error);
      toast.error("Erro inesperado tente mais tarde");
    })
  })

  useEffect(() => {
    if (message) {
      setMsg(message.description);
    }
  }, [message]);

  return (
    <>
      {message && <>
        {message.is_tutor ? <div className={styles.quillItem}><ReactQuill
          value={msg}
          theme="snow"
          placeholder="Digite suas anotações aqui..."
          className={`${styles.anotation} ${readonly ? styles.readonly : ""}`}
          modules={modules}
          onChange={(text) => setMsg(text)}
          readOnly={readonly}
        />
          <button disabled={isLoading} className="btn btn-primary" onClick={() => mutate({context_id: contextId, message_id: message.id, description: msg})}>
            {isLoading ? "Salvando..." : "Salvar"}
          </button>
          <div className={styles.answerNames}>
            <span>{message.name} - {message.created_at}</span>
          </div>
        </div> : <div key={message.id} className={styles.answerItemWrapper}>
          <div className={styles.answerItem} dangerouslySetInnerHTML={{__html: message.description}}></div>
          <div className={styles.answerNames}>
            <span>{message.name} - {message.created_at}</span>
          </div>
        </div>}
      </>}

      {!message && <>
        <div className={styles.quillItem}><ReactQuill
          value={msg}
          theme="snow"
          placeholder="Digite suas anotações aqui..."
          className={`${styles.anotation} ${readonly ? styles.readonly : ""}`}
          modules={modules}
          onChange={(text) => setMsg(text)}
          readOnly={readonly}
        />
          <button disabled={isLoading} className="btn btn-primary"  onClick={() => mutate({context_id: contextId, description: msg})}>
            {isLoading ? "Salvando..." : "Responder"}
          </button>
        </div>
      </>}
    </>
  )
}

export default MessageQuill;
