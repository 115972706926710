export const TOKEN_KEY = "Token";

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const removeAllLocalStorage = () => {
    localStorage.removeItem(TOKEN_KEY);
}

export const checkLogout401 = (error:any) => {
    if (error && error.toString().includes('code 401')) {
        removeAllLocalStorage();
        window.location.href = '/';
    }
};
