import React, {useState} from 'react';
import styles from "./styles.module.scss";
import Paginations from 'react-bootstrap/Pagination';
import {IFilter} from "../../views/Context/Context";

interface PaginateProps {
  itemsPerPage: number;
  totalRegisters: number;
  paginate: (page: React.SetStateAction<number>) => void;
  filter: any;
  setFilter: Function;
}

const Pagination = ({ itemsPerPage, totalRegisters, paginate, filter, setFilter }: PaginateProps) => {
  const pageNumbers = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [beginPage, setBeginPage] = useState(0);
  const [endPage, setEndPage] = useState(5);

  for (let i = 1; i <= Math.ceil(totalRegisters / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const changePage = (number: React.SetStateAction<number>) => {
    if (currentPage === number) return;

    setFilter({
      ...filter,
      'page': number
    });

    setCurrentPage(number);

    // @ts-ignore
    if (pageNumbers.length > endPage || beginPage === (number - 1))
    {
      if (number > 2)
      {
        // @ts-ignore
        if (pageNumbers.length < (number + 2))
        {
          setEndPage(pageNumbers.length);
          setBeginPage(pageNumbers.length - 5)
        }
        else
        {
          // @ts-ignore
          setEndPage(number + 2);
          // @ts-ignore
          setBeginPage(number - 3)
        }
      }
      else if (number > 1)
      {
        // @ts-ignore
        setEndPage(number + 3);
        setBeginPage(0)
      }
    }
    else if (number === 1)
    {
      // @ts-ignore
      setEndPage(number + 4);
      setBeginPage(0)
    }

    paginate(number);
  };

  const onPageNumberClick = (number: React.SetStateAction<number>) => {
    changePage(number);
  };

  const onFirstPageClick = () => {
    if (currentPage !== 1)
      changePage(1);
  };

  const onPreviousPageClick = () => {
    if (currentPage !== 1)
      changePage(currentPage - 1);
  };

  const onNextPageClick = () => {
    if (currentPage !== pageNumbers.length)
      changePage(currentPage + 1);
  };

  const onLastPageClick = () => {
    if (currentPage !== pageNumbers.length)
      changePage(pageNumbers.length);
  };

  return (
    <div className={styles.align}>
      {pageNumbers.length > 0 ? <Paginations>
        <Paginations.First onClick={() => onFirstPageClick()} />
        <Paginations.Prev onClick={() => onPreviousPageClick()} />
        {pageNumbers.slice(beginPage, endPage).map(number => (
          <Paginations.Item key={number}
                            active={number === currentPage}
                            onClick={() => onPageNumberClick(number)}
          >
            {number}
          </Paginations.Item>
        ))}
        <Paginations.Next onClick={() => onNextPageClick()} />
        <Paginations.Last onClick={() => onLastPageClick()} />
      </Paginations> : ""}
    </div>
  );
};

export default Pagination;
