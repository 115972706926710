import React from 'react'
import styles from "./styles.module.scss";

import logo from "../../assets/icons/logo.svg";
import lock from "../../assets/icons/lock.svg";

const Header = () => {
    return (
        <div className={styles.header}>
            <div className={styles.align}>
                <a href={"/"}><img src={logo} alt={"logo"}/></a>
            </div>
        </div>
    )
}

export default Header
