import {ToastContainer} from 'react-toastify';
import {CookiesProvider} from 'react-cookie';

import 'react-toastify/dist/ReactToastify.css';

import AppRouter from './routes/Routes';

function App() {
  return (
    <>
      <CookiesProvider>
        <AppRouter/>
      </CookiesProvider>
      <ToastContainer/>
    </>
  );
}

export default App;
