import React, {useEffect, useState} from 'react';
import {useMutation, useQuery} from "react-query";
import {Row, Col, Form, Label, Button, Input} from "reactstrap";
import DatePicker, {registerLocale} from "react-datepicker";

import styles from "./styles.module.scss";

import {
  deleteContextType, getTotalContextType,
  getTypeCalleds,
  ICalled,
  ICalledFilter,
  ICalleds, storeOrUpdateContextType
} from "../../services/services";

import {checkLogout401} from "../../services/auth";


import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';

import Preloader from "../../component/Preloader";
import ModalConfirm from "../../component/ModalConfirm";
import ModalStoreOrUpdateTypeCalled from "../../component/ModalStoreOrUpdateTypeCalled";
import editIcon from "../../assets/icons/edit.svg";
import deleteIcon from "../../assets/icons/graytrash.svg";
import {queryClient} from "../../services/queryClient";
import {toast} from "react-toastify";


export interface IFilter {
  created_at: string | null | string[];
}

const Called = () => {
  registerLocale('ptBR', ptBR);
  // const [filter, setFilter] = useState<IFilter>({
  //   created_at: [
  //     new Date(new Date().getFullYear(), new Date().getMonth(), 1).toLocaleDateString(),
  //     new Date().toLocaleDateString()
  //   ]
  // });
  const [calleds, setCalleds] = useState<ICalleds>({
    total: 0,
    last_page: 1,
    current_page: 1,
    data: [],
    id: 0
  })

  const [calledfilter, setCalledfilter] = useState<ICalled>({
    id: 0,
    name: '',
    total_calls: 0,
  })

  const [filter, setFilter] = useState<ICalledFilter>({
    name: '',
    page: 1,
  });

  const [aux, setAux] = useState<ICalledFilter>({
    name: '',
    page: 1,
  });


  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [openModalCreate, setOpenModalCreate] = useState(false)
  const [openModalUpdate, setOpenModalUpdate] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [saving, setSaving] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [idTypeCalled, setIdTypeCalled] = useState(0)
  const [contextTypes, setContextTypes] = useState<[]>([])
  const [contextTypesCall, setContextTypesCall] = useState<[]>([])

  const {
    data,
    isLoading
  } = useQuery(['calleds', filter.name , filter.page], () => getTypeCalleds(filter), {
    onSuccess: (data) => {
    },
    onError: error => {
      checkLogout401(error)
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: true,
  })

  useEffect(() => {
      setContextTypes(data?.data?.context_types)
  }, [data]);

  const {mutate} = useMutation((data: ICalled) => storeOrUpdateContextType(data), {
    onSuccess: ({data}) => {
      if (data.success) {
        queryClient.invalidateQueries(['calleds', filter.name, filter.page]);
        toast.success(data.msg);
        if (openModalCreate) {
          setOpenModalCreate(false)
        } else {
          setOpenModalUpdate(false)
        }
      } else {
        toast.error(data.msg);
      }
      setSaving(false)
    },
    onError: (error => {
      console.error(error);
      toast.error("Erro inesperado tente mais tarde");
    })
  })

  const storeOrUpdate = (typeCalled: ICalled) => {
    setSaving(true)
    mutate(typeCalled)
  }

  const destroyTypeCalled = () => {
    setLoadingDelete(true)
    deleteContextType(idTypeCalled).then((result) => {
      if (result.data && result.data.success) {
        queryClient.invalidateQueries(['calleds', filter.name, filter.page]);
        toast.success(result.data.msg)
      } else {
        toast.error(result.data.msg)
      }
    }).catch((e) => {
      checkLogout401(e)
    }).finally(() => {
      setOpenModalConfirm(false)
      setLoadingDelete(false)
    })
  }

  const handleChange = (name: string, value: string) => {
    setAux({
      ...aux,
      [name]: value
    })
  }

  useEffect(() => {
    const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    setDateRange([firstDayOfMonth, new Date()]);
  }, []);

  return (
    <>
      <Preloader isVisible={isLoading}/>
      <div className={styles.content}>
        <div className={styles.box}>
          <div className={styles.boxHeader}>
            <div className={styles.filter}>
              <Form onSubmit={(e) => {
                e.preventDefault();
                setFilter(aux);
              }}>
                <Row className={"row-cols-lg-auto g-3 align-items-center"}>
                  <div className={styles.filter}>
                    <Input
                      id="name"
                      name="name"
                      placeholder="Tipo"
                      type="text"
                      onChange={(e) => {
                        handleChange('name', e.target.value)
                      }}
                    />
                  </div>
                  <Col xl={10} className={styles.colBtn}>
                    <button className={styles.filterBtn} disabled={false}>
                      {isLoading ? "Filtrando..." : "Filtrar"}
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className={styles.add}>
            <Button disabled={isLoading} className={isLoading ? styles.buttonDisable : ''} onClick={() => {
              setOpenModalCreate(true)
              setIdTypeCalled(0)
            }}>
              {isLoading ? 'Carregando...' : 'Adicionar'}
            </Button>
          </div>
          <Col xl={12}>
            <div className={styles.totalItems}>
              <p className={styles.textDecoration}>Total: {data && data.data?.total > 0 ? data.data.total : ''}</p>
            </div>
          </Col>
          <div className={styles.table}>
            <table className='table table-responsive'>
              <thead>
              <tr>
                <th>Tipo</th>
                <th>Total Chamados</th>
                <th>Editar</th>
                <th>Excluir</th>
              </tr>
              </thead>
              {contextTypes?.map((item: ICalled, index: number) => {
                return (
                  <tbody key={index}>
                  <tr>
                    <td>{item.name}</td>
                    <td>{item?.total_calls}</td>
                    <td>
                      <Button className={styles.editButton} onClick={() => {
                        setIdTypeCalled(item.id)
                        setOpenModalUpdate(true)
                      }}>
                        <img src={editIcon} alt={'edit'}/>
                      </Button>
                    </td>
                    <td>
                      <Button className={styles.deleteButton} onClick={() => {
                        setIdTypeCalled(item.id)
                        setOpenModalConfirm(true)
                      }}>
                        <img src={deleteIcon} alt={'delete'}/>
                      </Button>
                    </td>
                  </tr>
                  </tbody>
                )
              })}
            </table>
          </div>
        </div>
      </div>
      {openModalCreate &&
        <ModalStoreOrUpdateTypeCalled action={storeOrUpdate} setOpen={setOpenModalCreate} open={openModalCreate}
                                              idTypeCalled={0}
                                              saving={saving}/>
      }
      {openModalUpdate &&
        <ModalStoreOrUpdateTypeCalled action={storeOrUpdate} setOpen={setOpenModalUpdate} open={openModalUpdate}
                                              idTypeCalled={idTypeCalled} saving={saving}/>
      }
      {openModalConfirm &&
        <ModalConfirm loading={loadingDelete}
                      open={openModalConfirm}
                      title={'Excluir'}
                      description={'Deseja mesmo excluir esse tipo de chamado?'}
                      textButton={'Excluir'}
                      setOpen={(state) => {
                        setOpenModalConfirm(state)
                      }}
                      action={destroyTypeCalled}
                      btnConfirmColor={"danger"}
        />
      }
    </>
  )
}

export default Called
