import styles from "./styles.module.scss";
import {Modal, ModalBody} from "reactstrap";
import React, {useEffect, useState} from "react";
import {CSpinner} from "@coreui/react";
import {Button, Input} from "reactstrap";
import {getContext, getTutor, ITutor, ITutorFilter, ITutors, storeOrUpdateTutor} from "../../services/services";
import {toast} from "react-toastify";
import {useMutation, useQuery} from "react-query";
import {queryClient} from "../../services/queryClient";
import {checkLogout401} from "../../services/auth";

interface ModalProps {
  saving: boolean;
  open: boolean;
  idTutor: number;
  setOpen: (data: boolean) => void;
  action: (data: ITutor) => void;
}

const ModalStoreOrUpdateTutor = ({open, idTutor, setOpen, action, saving}: ModalProps) => {

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [tutor, setTutor] = useState<ITutor>({
    id: idTutor,
    name: '',
    email: '',
    password: '',
    created_at : ''
  })

  useEffect(() => {
    if (idTutor > 0) {
      setLoading(true)
      getTutor(idTutor).then((result) =>{
        if (result && result.data){
          setTutor({
            ...tutor,
            id: result.data.id,
            email: result.data.email,
            name: result.data.name
          })
        }
      }).catch((e)=>{
        checkLogout401(e)
      }).finally(()=>{
        setLoading(false)
      })
    }
  }, [idTutor])

  const handleChange = (name: string, value: string) => {
    setTutor({
      ...tutor,
      [name]: value
    })
  }

  const toggle = () =>{
    setOpen(false)
  }

  const verify = () =>{
    if (((tutor.email === '' || tutor.name === '' || tutor.password === '') && tutor.id === 0) || ((tutor.email === '' || tutor.name === '') && tutor.id > 0)){
      toast.error("Preencha todos os campos!");
      return false
    }

    action(tutor)
  }

  return (
    <Modal toggle={toggle} isOpen={open} className={styles.modalContent}>
      <div className={styles.close}>
        <button onClick={toggle}>&times;</button>
      </div>
      <strong>{idTutor > 0 ? 'Editar Tutor' : 'Adicionar Tutor'}</strong>
      {loading ?
        <div className={styles.spinner}>
          <CSpinner className={styles.loading}/>
        </div>
        :
        <ModalBody>
          <div className={styles.inputCards}>
            <div>
              <small>Nome:</small>
              <Input
                id="name"
                name="name"
                type="text"
                value={tutor.name}
                onChange={(e) => {
                  handleChange('name', e.target.value)
                }}
              />
            </div>
            <div>
              <small>Email:</small>
              <Input
                id="email"
                name="email"
                type="email"
                value={tutor.email}
                required
                onChange={(e) => {
                  handleChange('email', e.target.value)
                }}
              />
            </div>
            <div>
              <small>Senha:</small>
              <Input
                id="password"
                name="password"
                type={visible ? 'text' : 'password'}
                onChange={(e) => {
                  handleChange('password', e.target.value)
                }}
              />
            </div>
          </div>
          <div className={styles.inputButtons}>
            <Button disabled={saving} className={saving ? styles.buttonDisable : ""} onClick={toggle}>
              {saving ? 'Carregando...' : 'Cancelar'}
            </Button>
            <Button disabled={saving} className={saving ? styles.buttonDisable : ""} onClick={verify}>
              {saving ? 'Carregando...' : 'Salvar'}
            </Button>
          </div>
        </ModalBody>
      }
    </Modal>
  )

}
export default ModalStoreOrUpdateTutor
